.director-conatainer {
  background-image: url("../Assets/background.jpeg");
  background-size: cover;
  background-attachment: fixed;
  color: aliceblue;
  text-align: center;
  padding-bottom: 60px;
  padding-top: 50px;
}
.hr-director {
  width: 100%;
  color: rgb(236, 28, 36);
}
.img-director {
  border-radius: 12%;
  /* width: 100%; */
  height: 200px;
  width: 170px;
  padding: 2px;
  border: 3px solid rgb(236, 28, 36);
}
.firstname {
  margin: 0px;
  text-align: left;
}
.phone {
  text-align: left;
}
.secondname {
  font-size: 38px;
  text-align: left;
  margin: 0px;
  font-weight: 900;
}
.director-para {
  margin-bottom: 0px;
  padding-bottom: 30px;
}
@media (max-width: 1000px) {
  .director-conatainer {
    padding-bottom: 20px;
  }
  .secondname {
    text-align: center;
  }
  .firstname {
    text-align: center;
  }
  .phone {
    text-align: center;
  }
}
