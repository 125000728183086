.service-card {
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* width: 300px; */
  /* padding: 10px; */
  /* margin-right: 20px; */
  /* padding: 20px; */
  width: 400px;
  min-height: 550px;
}

.image-service-one {
  background-size: cover;
  background-attachment: fixed;

  border-radius: 40px;
  border: 6px solid #44ac33;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-height: 400px;
}
.service-one-container {
  background-image: url("../../Assets/background.jpeg");
  background-size: cover;
  background-attachment: fixed;
  height: 700px;
}
.service-one-head {
  text-align: center;
  text-transform: uppercase;
  color: #44ac33;
}
.service-one-para {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  /* height: 100vh; */
}
.navbar-container-service {
  background-color: #303030;
  margin-bottom: 50px;
}
.service-card-container {
  background-image: url("../../Assets/background.jpeg");

  background-size: cover;
  background-attachment: fixed;
  /* height: 700px; */
  padding: 50px;
}
.services-header {
  margin: 0px;
  text-align: center;
  font-size: 39px;
  color: #303030;
  font-weight: 700;
}
@media (max-width: 1000px) {
  .service-one-container {
    height: 100vh;
  }
  .service-card-container {
    padding: 20px;
  }
  .services-header {
    font-size: 30px;
    padding-top: 20px;
  }
  .service-card {
    width: 330px;
  }
}
